<template>
    <div class="page recordBox">
        <el-row :gutter="10">
            <el-col :span="showList ? 4 : 1" :class="['p_r', showList ? '' : 'el-col-unfold']">
                <div :class="['unfoldClassifyBtn', showList ? 'el-icon-arrow-left':'el-icon-arrow-right']"
                     @click="showList = !showList"></div>
                <div class="bg-white" v-show="showList">
                    <el-descriptions title="档案分类"></el-descriptions>
                    <el-tree class="treeData" :data="treeData" :props="defaultProps" v-loading="treeLoading"
                             highlight-current
                             node-key="id" ref="myTree" @node-click="selRecord">
                        <div slot-scope="{ node, data }" class="omit">
                            <el-tooltip class="item" effect="dark" :content="node.label" placement="top-start">
                                <div>{{ node.label }}</div>
                            </el-tooltip>
                        </div>
                    </el-tree>
                </div>
            </el-col>
            <el-col :span="showList ? 20 : 24" :class="[showList ? '' : 'el-col-unfold']">
                <el-form class="query-form" size="small" :inline="true" ref="searchForm" :model="searchForm"
                         label-width="88px">
                    <el-form-item prop="keyWord" label="输入搜索：">
                        <el-input v-model.trim="searchForm.keyWord" maxlength="50" placeholder="请输入关键字（50字内）"
                                  clearable style="width: 190px" @keyup.enter.native="search(1)"></el-input>
                    </el-form-item>
                    <el-form-item prop="createStartDate" :label="getType() + '时间：'"
                                  v-show="searchForm.utilizeType != '-1'">
                        <el-date-picker v-model="createDate" value-format="yyyy-MM-dd HH:mm:ss"
                                        format="yyyy-MM-dd HH:mm:ss"
                                        type="datetimerange" clearable start-placeholder="开始时间"
                                        end-placeholder="结束时间" style="width: 330px">
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" icon="el-icon-search" @click="search(1)" size="small">查询</el-button>
                        <el-button icon="el-icon-refresh-right" @click="reset()" size="small">重置</el-button>
                    </el-form-item>
                </el-form>
                <div class="bg-white">
                    <div class="flex_b_c">
                        <el-tabs v-model="searchForm.utilizeType" size="small" @tab-click="reset()">
                            <el-tab-pane :label="'全部'+'('+countInfo.allCount+')'" name="-1"></el-tab-pane>
                            <el-tab-pane :label="'查看'+'('+countInfo.queryCount+')'" name="0"></el-tab-pane>
                            <el-tab-pane :label="'下载'+'('+countInfo.downloadCount+')'" name="1"></el-tab-pane>
                            <el-tab-pane :label="'打印'+'('+countInfo.printCount+')'" name="2"></el-tab-pane>
                        </el-tabs>
                        <el-button type="danger" size="small" icon="el-icon-delete"
                                   v-show="hasPermission('record:history:delete')"
                                   @click="del()">批量删除
                        </el-button>
                    </div>
                    <el-table
                            :data="dataList"
                            v-loading="loading"
                            size="small"
                            height="calc(100vh - 340px)"
                            @selection-change="selectionChangeHandle"
                            class="table" ref="multipleTable">
                        <el-table-column type="selection" fixed/>
                        <el-table-column prop="archivesDataName" label="题名" min-width="120" show-overflow-tooltip/>
                        <el-table-column prop="warehousingState" label="状态" show-overflow-tooltip></el-table-column>
                        <el-table-column prop="quanzongNumber" label="全宗号" min-width="120"
                                         show-overflow-tooltip></el-table-column>
                        <el-table-column prop="quanzongName" label="全宗名称" min-width="120"
                                         show-overflow-tooltip></el-table-column>
                        <el-table-column prop="reference" label="档号" min-width="120"
                                         show-overflow-tooltip></el-table-column>
                        <el-table-column v-for="item in config" :key="item.archivesBasicId"
                                         :prop="item.code"
                                         :label="item.forName ? item.forName : item.basicName"
                                         v-if="item.dataSelect == 0"
                                         :sortable="item.archivesBasicTypeCode == 'date' || item.archivesBasicTypeCode == 'integer' || !item.archivesBasicTypeCode ? true : false"
                                         show-overflow-tooltip min-width="120">
                            <template slot="header" slot-scope="scope">
                                <el-tooltip class="item" effect="dark"
                                            :content="item.forName ? item.forName : item.basicName"
                                            placement="top-start">
                                    <span class="omit">{{item.forName ? item.forName : item.basicName}}</span>
                                </el-tooltip>
                            </template>
                            <template slot-scope="scope">
                                <span v-if="item.archivesBasicTypeCode == 'date'">{{metadataDateFormatValue(item, scope.row.archivesData[item.code])}}</span>
                                <span v-else>{{ scope.row.archivesData[item.code]}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="createType" label="操作" min-width="120" show-overflow-tooltip
                                         v-if="searchForm.utilizeType == '-1'">
                            <template slot-scope="scope">
                                <span>{{getName(scope.row.utilizeType)}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="createByName" v-if="hasPermission('record:history:user')"
                                         :label="getName() + '用户'" min-width="120"
                                         show-overflow-tooltip></el-table-column>
                        <el-table-column prop="createDate" :label="getName() + '时间'" sortable min-width="120" sortable
                                         show-overflow-tooltip></el-table-column>
                        <el-table-column fixed="right" width="180" label="操作">
                            <template slot-scope="scope">
                                <el-button type="text" size="small" v-show="hasPermission('record:history:view')"
                                           @click="view(scope.row, 'view')">详情
                                </el-button>
                                <el-button type="text" size="small" v-show="hasPermission('record:history:delete')"
                                           @click="del(scope.row.id)">删除
                                </el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                    <el-pagination
                            @size-change="sizeChangeHandle"
                            @current-change="currentChangeHandle"
                            :current-page="searchForm.current"
                            :page-sizes="[10, 20, 50, 100]"
                            :page-size="searchForm.size"
                            :total="total"
                            background
                            layout="total, sizes, prev, pager, next, jumper">
                    </el-pagination>
                </div>
            </el-col>
        </el-row>
        <ViewPage ref="viewPage"></ViewPage>
    </div>
</template>

<script>
    import ViewPage from './viewPage'

    export default {
        components: {
            ViewPage
        },
        data() {
            return {
                showList: true,
                treeData: [],
                treeLoading: false,
                defaultProps: {
                    children: 'children',
                    label: 'archivesBasicName'
                },
                searchForm: {
                    keyWord: '',
                    createStartDate: '',
                    createEndDate: '',
                    utilizeType: '-1',
                    archivesBasicDataId: '',
                    current: 1,
                    size: 10,
                },
                createDate: [],
                loading: false,
                dataList: [],
                total: 0,
                dataListSelections: [],
                config: [],
                listConfig: [],
                countInfo: {
                    allCount: 0,
                    queryCount: 0,
                    printCount: 0,
                    downloadCount: 0,
                }
            }
        },
        mounted() {
            this.searchTree()
            this.getAllData()
        },
        methods: {
            // 检索
            search(type) {
                this.getCount()
                if (type == 1) {
                    this.searchForm.current = 1
                }
                this.loading = true
                let p = JSON.parse(JSON.stringify(this.searchForm))
                if (p.utilizeType == -1) {
                    p.utilizeType = ''
                }
                p.createStartDate = this.createDate ? this.createDate[0] : ''
                p.createEndDate = this.createDate ? this.createDate[1] : ''
                this.$axios(this.api.record.getArchivesUtilizeByCreateBy, p, 'get').then(res => {
                    if (res.status) {
                        this.dataList = res.data.records
                        if (this.dataList.length == 0 && this.searchForm.current > 1) {
                            this.searchForm.current--
                            this.search()
                        }
                        this.total = parseInt(res.data.total)
                    } else {
                        this.$message.error('查询失败');
                    }
                    this.loading = false
                    this.$nextTick(() => {
                        this.$refs.multipleTable.doLayout()
                    })
                })

            },
            getName(val) {
                let type = val ? val : this.searchForm.utilizeType
                switch (type) {
                    case "-1":
                        return '操作';
                        break
                    case "0":
                        return '查看';
                        break
                    case "1":
                        return '下载';
                        break
                    case "2":
                        return '打印';
                        break
                }
            },
            // 获取总数
            getCount() {
                this.$axios(this.api.record.getArchivesUtilizeByCountGroup, {archivesBasicDataId: this.searchForm.archivesBasicDataId}).then((res) => {
                    if (res.status) {
                        this.countInfo = this.recover(this.countInfo, res.data)
                        this.countInfo.allCount = this.countInfo.queryCount + this.countInfo.printCount + this.countInfo.downloadCount
                    }
                })
            },
            // 全部分类元数据字段
            getAllData() {
                this.$axios(this.api.record.getArchivesBasicByRequired).then((res) => {
                    if (res.status) {
                        this.config = res.data
                        this.search(1)
                    }
                })
            },
            // 获取档案分类元数据字段
            getConfig() {
                this.$axios(this.api.record.basicdataGetById + '/' + this.searchForm.archivesBasicDataId).then(res => {
                    if (res.status) {
                        this.listConfig = []
                        this.config = res.data.archivesBasicDataGroup
                        this.config.map(item => {
                            if (item.dataSelect == 0) {
                                this.listConfig.push(item)
                            }
                        })
                        this.search(1)
                    }
                })
            },
            // 查询树状数据
            searchTree() {
                this.treeLoading = true
                this.$axios(this.api.record.selectArchivesBasicData).then((res) => {
                    if (res.status) {
                        this.treeData = res.data
                        this.treeData.unshift({
                            archivesBasicName: '全部',
                            id: '',
                            children: []
                        })
                        this.$nextTick(() => {
                            this.$refs.myTree.setCurrentKey(this.treeData[0].id);
                        });
                    }
                    this.treeLoading = false
                })
            },
            // 选择档案分类
            selRecord(val) {
                this.searchForm.utilizeType = '-1'
                this.searchForm.keyWord = ''
                this.searchForm.archivesBasicDataId = val.id
                if (val.id != '') {
                    this.getConfig()
                } else {
                    this.getAllData()
                }
                this.getCount()
            },
            getType() {
                if (this.searchForm.utilizeType == 0) {
                    return '查看'
                }
                if (this.searchForm.utilizeType == 1) {
                    return '下载'
                }
                if (this.searchForm.utilizeType == 2) {
                    return '打印'
                }
            },
            // 查看
            view(row, method) {
                this.$refs.viewPage.init(row, method, row.archivesBasicDataId, 'archivesDataId')
            },
            // 删除
            del(id) {
                let ids = [id];
                if (!id && !this.dataListSelections.length) {
                    this.$message.warning('请至少选择一条数据')
                    return
                }
                if (!id) {
                    ids = this.dataListSelections.map(item => {
                        return item.id
                    })
                }
                this.$confirm(`确定删除所选项吗?`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.$axios(this.api.record.archivesutilizeRemoveById, ids, 'post').then(data => {
                        if (data.status) {
                            this.$message.success('删除成功')
                            this.search()
                        } else {
                            this.$message.error('删除失败')
                        }
                    })
                })
            },
            //重置
            reset() {
                this.$refs.searchForm.resetFields();
                this.createDate = []
                this.search(1)
            },
            // 多选
            selectionChangeHandle(val) {
                this.dataListSelections = val
            },
            // 每页数
            sizeChangeHandle(val) {
                this.searchForm.size = val
                this.searchForm.current = 1
                this.search()
            },
            // 当前页
            currentChangeHandle(val) {
                this.searchForm.current = val
                this.search()
            },
        }
    }
</script>

<style scoped>
    .table {
        margin-top: 0 !important;
    }
</style>
